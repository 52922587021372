

.space {
  display:flex;
  margin-bottom: 8px;
  div {
    margin-right: 2px;
    flex-shrink: 0;
  }
}
