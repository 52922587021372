.editor {
  & :global {
	.ql-container,
	.ql-editor {
	  min-height: 150px;
	}

	.ql-editing.ql-tooltip {
	  left: 0 !important;
	}
  }
}
