.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  padding: 0 24px;
  background-color: white;
}

.wrapper {
  text-align: center;
  line-height: 20px;
}
.name {
  display: block;
  margin: 0;
}

.email {
  font-size: 12px;
  color: #00000073;
}
