.files {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  gap: 20px;
  align-items: flex-start;
  overflow-y: auto;
  height: 53vh;
  width: 100%;
}