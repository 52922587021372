.wrapper {
  :global(.ant-image) {
    height: 100%;
  }
}

.image {
  height: 100%;
  object-fit: contain;
}
