.thumb {
  display: flex;
  flex-direction: column;
  padding: 5px 15px;
  position: relative;
}

.check {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 100;
  visibility: hidden;
}

.image {
  margin: auto;
}

.thumb.active,
.thumb:hover {
  background-color: #f0f5ff;
}

.thumb.active .check,
.thumb:hover .check {
  visibility: visible;
}

.thumb.active .text,
.thumb:hover .text {
  background-color: white;
}


.text {
  text-align: center;
}