.row {
  display: grid;
  grid-template-columns: 250px 5px 1fr;
  height: 100%;
  gap: 10px;
}

.folders {
  width: 100%;
  flex-shrink: 0;
  flex-grow: 0;
  height: 65vh;
  overflow-y: auto;
}

.dividerWrapper {
  display: flex;
  justify-content: center;
  width: 5px;
  height: 100%;
}

.divider {
  height: 100%;
}
