.layout {
  min-height: 100vh;
}

.logo {
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px;

  img {
	width: 100%;
	height: 100%;
	object-fit: contain;
  }
}

.content {
  margin: 24px 24px 0 24px;
}

.contentWrapper {
  padding: 24px;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
}
